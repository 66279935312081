import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import i18next from "i18next";
import { Container, Row } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BannerHomeNew.scss";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import rioBoatTourDeskPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho.jpg";
import rioBoatTourDeskEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en.jpg";
import rioBoatTourDeskEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es.jpg";

import rioBoatTourMobPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-mobile.jpg";
import rioBoatTourMobEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en-mobile.jpg";
import rioBoatTourMobEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es-mobile.jpg";
import BannerApiOut from "../BannerApiOut/BannerApiOut";


export interface props {

};

const BannerHomeNew: React.FC<props> = () => {

    const [allBanners, setAllBanners] = useState<any>(null);

    const getBanners = async (lng: string) => {

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.get(`${process.env.REACT_APP_SERVER_URL_API_NODE}/banners_home/${lng}`, config);
            if (res.status !== 400) {
                setAllBanners(() => res.data.data);
            } else {
                setAllBanners([]);
            }
        } catch (error) {
            setAllBanners([]);
        }
    }

    useEffect(() => {
        getBanners(i18next.language.slice(0, 2));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language])

    const settings = {
        draggable: false,
        useTransform: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5500,
        cssEase: "ease-out",
    };

    const typeDevice = window.innerWidth < 480 ? "Mobile" : window.innerWidth >= 480 && window.innerWidth < 1024 ? "Medium" : "Desktop";
    const bannerHeigth = window.innerWidth < 480 ? 640 : window.innerWidth >= 480 && window.innerWidth < 1024 ? 480 : 533;

    return (
        <div className="bg-main-banner" >
            {allBanners?.length > 0 ?
                <>
                    <Slider {...settings}>
                        {
                            allBanners.map((bannerElem: any, bannerIndex: number) => {
                                let bannerSize: any = bannerElem.image.filter((elem: any) => elem.size === typeDevice)[0];
                                
                                //verifica se alguma imagem esta vazia
                                if (!bannerSize.image) return null;

                                return (
                                    <div>
                                        <Link
                                            to={{
                                                pathname: bannerSize.link,
                                            }}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Container className="bg-secondary banner-home p-0 m-0" fluid>
                                                <Container className="p-0 m-0" fluid>
                                                    <Row className="p-0 m-0">
                                                        <img
                                                            className="p-0 m-0"
                                                            src={bannerSize.image}
                                                            alt={bannerSize.alt}
                                                            loading="lazy"
                                                        />
                                                    </Row>
                                                </Container>
                                            </Container>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </>
                :
                allBanners === null ? //preLoad
                    <>
                        <div className='animated-background' style={{ height: `${bannerHeigth}px`, width: "100%" }}></div>
                    </>
                    :
                    <>
                    <BannerApiOut />
                    </>
                    // <Link
                    //     to={
                    //         i18next.language === "pt"
                    //             ? 'tour/rio-boat-pt'
                    //             : i18next.language === "en"
                    //                 ? 'tour/rio-boat-en'
                    //                 : i18next.language === "es"
                    //                     ? 'tour/rio-boat-es'
                    //                     : 'tour/rio-boat-pt'
                    //     }
                    // >
                    //     <Container className="bg-secondary banner-home p-0 m-0" fluid>
                    //         <Container className="p-0 m-0" fluid>
                    //             <Row className="p-0 m-0">
                    //                 {typeDevice === "Desktop" ? (
                    //                     <img
                    //                         className="p-0 m-0"
                    //                         src={
                    //                             i18next.language === "pt"
                    //                                 ? rioBoatTourDeskPt
                    //                                 : i18next.language === "en"
                    //                                     ? rioBoatTourDeskEn
                    //                                     : i18next.language === "es"
                    //                                         ? rioBoatTourDeskEs
                    //                                         : rioBoatTourDeskPt
                    //                         }
                    //                         alt="default banner"
                    //                         loading="lazy"
                    //                     />
                    //                 ) : (
                    //                     <img
                    //                         className="p-0 m-0"
                    //                         src={
                    //                             i18next.language === "pt"
                    //                                 ? rioBoatTourMobPt
                    //                                 : i18next.language === "en"
                    //                                     ? rioBoatTourMobEn
                    //                                     : i18next.language === "es"
                    //                                         ? rioBoatTourMobEs
                    //                                         : rioBoatTourMobPt
                    //                         }
                    //                         alt="default banner"
                    //                         loading="lazy"
                    //                     />
                    //                 )}
                    //             </Row>
                    //         </Container>
                    //     </Container>
                    // </Link>
            }
        </div >
    );
}

export default BannerHomeNew;