import { useEffect } from 'react';

// Declarar a propriedade RDStationForms no objeto window
declare global {
  interface Window {
    RDStationForms: any;
  }
}

const RdStationForm = () => {
  useEffect(() => {
    // Carregar o script do RD Station Forms
    const script = document.createElement('script');
    script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
    script.async = true;
    document.body.appendChild(script);

    // Inicializar o RDStationForms após o script ser carregado
    script.onload = () => {
      if (window.RDStationForms) {
        new window.RDStationForms('cadastro-site-rodape-c2-1350195a6f6a02281e91', null).createForm();
      }
    };

    return () => {
      // Remover o script se o componente for desmontado
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div role="main" id="cadastro-site-rodape-c2-1350195a6f6a02281e91"></div>
  );
};

export default RdStationForm;
