/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
import React, { useState, useEffect, Key } from "react";
import { Row } from "react-bootstrap";
import api from "../../services/api";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import "./TransferList.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../../components/Header/Header";
import Footer from "../../components/NewFooter/NewFooter";
import BannerHome from "../../components/Banners/BannerHome/BannerHome";
import FilterTransfer from "./components/FilterTransfer/FilterTransfer";
import TabSearchs from "../Home/components/TabSearchs/TabSearchs";
import FilterModal from "./components/FilterModal/FilterModal";
import Pagination from "../../components/Pagination/Pagination";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addItemCart } from "../../store/cart";
import { Helmet } from "react-helmet";

function TransferList() {
    const [t] = useTranslation();
    const cartC2 = useAppSelector((state: any) => state.cart);
    const dispatch = useAppDispatch();

    const transferC2: any = localStorage.getItem("transferC2");
    const transferItemJSON = JSON.parse(transferC2);

    const [search, setSearch] = useState<any>(transferItemJSON !== null ? transferItemJSON : null);
    const [totalRows, setTotalRows] = useState<any>(0);
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);

    const [tourLists, setTourList] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const tagEvent = (item: any) => {        
        dispatch(addItemCart(item));
      }
    

    const auth = (list: any) => {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho
        console.log(list)

    cartC2.dados.forEach((elem: any) => {
      if (elem.productCode === list.productCode) {
        repeatedItem = true;
      }
    });

    if (repeatedItem === true) {
      alert("Produto já existe no carrinho!");
    } else {
        scrollTop()
        const item = {
            productName: list.transferVehicleType + ' ' + list.vehicleCategoryDetails.category + ' ' + list.transferServiceType,
            imgCart: list.vehicleCategoryDetails.categoryImagePath + (list.transferVehicleType === "Mini Van" ? "minivan-transfer.webp"
                : list.transferVehicleType === "Van" ? list.vehicleCategoryDetails.vanImageName
                    : list.transferVehicleType === "Ônibus" ? list.vehicleCategoryDetails.busImageName
                        : list.transferVehicleType === "Carro" ? list.vehicleCategoryDetails.carImageName
                            : list.transferVehicleType === "Micro Ônibus" ? list.vehicleCategoryDetails.microBusImageName : ""),
            price: Number(list.preco),

            idTarif: list.idTaxa,

            priceAdults: 0,
            priceChilds: 0,
            priceInfants: 0,
            priceElders: 0,
            priceStudent: 0,
            priceGlobalPeople: Number(list.preco),

            sellingType: list.idSellingType,
            adults: 0,
            childs: 0,
            infants: 0,
            elders: 0,
            student: 0,
            globalPeople: search.numPeople,
            productType: 2,
            productCode: list.productCode,
            time: search.time,
            date: search.date.split('/').reverse().join('-'),
            supplier: list.supplierIdentity,
            typePickup: 1,
            pickup: 0,

            discount: 0,
            customValueNet: 0,
            customValueSell: 0,
            goingSource: search.destine,
            commingSource: search.origin,
            latOrigem: search.latOrigem,
            lngOrigem: search.lngOrigem,
            latDestino: search.latDestino,
            lngDestino: search.lngDestino,
            cia: " ",
            voo: " ",
            smallSuitcase: list.handLuggage,
            bigSuitcase: list.baggagePerPerson,
            internalNotes: " ",
            idVeiculo: list.minMaxPeople.idTransferVehicles,
            passengers: []

        }
        tagEvent(item);
        console.log(cartC2)
    }

    }

    function scrollTop() {
        window.scrollTo(0, 0);
    
        var elem: any = document.getElementById("authCartDropdown");
        elem.classList.add("active");
    }

    // ScrollUP na páginação //
    function scrollTopPage() {
        window.scrollTo(0, 500);
    }

    useEffect(() => {
        scrollTopPage();
    }, [pageCount])
    // END - ScrollUP na páginação //

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Transfer - {process.env.REACT_APP_SERVER_NAME}</title>

                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content={"Transfer -" + process.env.REACT_APP_SERVER_NAME} />
                <meta property="og:type" content="TravelAgency" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_IMG_DEST}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL+"transfer"} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Pagina de pesquisa de translado - C2Rio Tours & Travel" />
                <meta property="og:site_name" content={"Transfer -" + process.env.REACT_APP_SERVER_NAME} />

                {/* Non-Essential, But Required for Analytics */}
                {/* <meta property="fb:app_id" content="your_app_id" /> */}

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL+"transfer"} />
            </Helmet>
            <Header />
            <div className="content-header">
                <BannerHome />
                <div className="container-fluid my-0 my-md-4">
                    <div className="container-fluid container-md nav-pills-c2">
                        <TabSearchs setSearch={setSearch} search={search} />
                    </div>
                </div>
            </div>
            {search !== null && search?.length === undefined
                ?
                <>
                    <div className="container-fluid content-page px-0">
                        <div className="container-fluid container-md">
                            <Row>
                                <div className="col-12 col-lg-3 d-none d-lg-block">
                                    <div className="card card-filter mb-4">
                                        <FilterTransfer
                                            transfers={tourLists}
                                            search={search}
                                            setSearch={setSearch}
                                            setTransfers={setTourList}
                                            setPageCount={setPageCount}
                                            setTotalRows={setTotalRows}
                                            setLoading={setLoading}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9 px-2 transfer-container">
                                    <div className="d-flex">
                                        <h1 className="pb-3 title-h1">Transfer - C2Rio Tours & Travel</h1>
                                        {/* Reponsividade */}
                                        <div className="d-block d-lg-none">
                                            <FilterModal
                                                name={'sliders-h'}
                                                center={'yes'}
                                                classBody="filter-mobile"
                                            >
                                                <FilterTransfer
                                                    transfers={tourLists}
                                                    search={search}
                                                    setSearch={setSearch}
                                                    setTransfers={setTourList}
                                                    setPageCount={setPageCount}
                                                    setTotalRows={setTotalRows}
                                                    setLoading={setLoading}
                                                />
                                            </FilterModal>
                                        </div>
                                        {/* Reponsividade */}

                                    </div>
                                    {tourLists?.length > 0 && loading === false
                                        ? tourLists.slice((pageCount * rowsPerPage) - rowsPerPage, (pageCount * rowsPerPage)).map((list: any, index: Key) => {
                                            return (
                                                <>
                                                    <div key={index} className="mb-4">
                                                        <div className="card">
                                                            <div className="row m-0">
                                                                <div className="col-12 col-md-3 p-0 p-md-4">
                                                                    <span className="badge rounded position-absolute badge-primary py-2 px-3 text-uppercase" style={{ display: "flex", alignItems: "center" }}>
                                                                        <FontAwesomeIcon
                                                                            key={index}
                                                                            icon={["fal", "car"]}
                                                                            size="2x"
                                                                            style={{
                                                                                margin: "0px 5px 0 0px",
                                                                                fontSize: "1em"
                                                                            }}
                                                                        />
                                                                        Transfer
                                                                    </span>
                                                                    <div className="bg-vehicles"
                                                                        style={{
                                                                            background: `url(${process.env.REACT_APP_SERVER_URL_IMG}${list.vehicleCategoryDetails.categoryImagePath}${list.transferVehicleType === "Mini Van" ? "minivan-transfer.webp"
                                                                                : list.transferVehicleType === "Van" ? list.vehicleCategoryDetails.vanImageName
                                                                                    : list.transferVehicleType === "Ônibus" ? list.vehicleCategoryDetails.busImageName
                                                                                        : list.transferVehicleType === "Carro" ? list.vehicleCategoryDetails.carImageName
                                                                                            : list.transferVehicleType === "Micro Ônibus" ? list.vehicleCategoryDetails.microBusImageName
                                                                                                : ""})`
                                                                        }}>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 p-3 text-transfer">
                                                                    <div>
                                                                        <h3>{`${list.transferVehicleType} ${list.vehicleCategoryDetails.category} ${list.transferServiceType}`}</h3>
                                                                    </div>
                                                                    <div>
                                                                        <small style={{ color: "#848289" }}>
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "clock"]}
                                                                                size="1x"
                                                                                style={{ fontSize: "14px", marginRight: "5px" }}
                                                                            />
                                                                            {t("transfer.duration")} <span>{list.duracao}</span>
                                                                        </small>
                                                                    </div>
                                                                    <div>
                                                                        <p>{t("transfer.leave")} <span className="time-list">{search.date} {t("transfer.at")} {search.time}</span></p>
                                                                    </div>
                                                                    <div className="row info-travel">
                                                                        <div className="col-4">
                                                                            <div className="d-flex justify-content-center">
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "users"]}
                                                                                    size="2x"
                                                                                    style={{ fontSize: "26px" }}
                                                                                />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <p>{list.minMaxPeople.maxPassengers} {t("transfer.people")}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="d-flex justify-content-center">
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "suitcase"]}
                                                                                    size="2x"
                                                                                    style={{ fontSize: "26px" }}
                                                                                />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <p>{list.baggagePerPerson} {t("transfer.suitcase")}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="d-flex justify-content-center">
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "suitcase-rolling"]}
                                                                                    size="2x"
                                                                                    style={{ fontSize: "26px" }}
                                                                                />
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <p>{list.handLuggage} {t("transfer.suitcaseHand")}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-3 card-price">
                                                                    <div className="list-price">
                                                                        <p>{list.idSellingType === 1 ? t("transfer.priceVeihicle") : t("transfer.pricePeople")}</p>
                                                                        <h3><small style={{ fontSize: "18px" }}>R$ </small>{`${list.preco.toFixed(2).replace('.', ',')}`}</h3>
                                                                        <button onClick={() => auth(list)}  className="btn btn-primary btn-list-transfers">{t("transfer.buy")}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        : loading === true
                                            ? [0, 0, 0, 0, 0].map((elem: any, index: Key) => {
                                                return (

                                                    <div className="card card-tour p-3 mb-4" key={index}>
                                                        <Row>
                                                            <div
                                                                className="col-5 col-md-4"
                                                                style={{ overflow: "hidden" }}
                                                            >
                                                                <div className="img animated-background h-100"></div>
                                                            </div>
                                                            <div className="col-7 col-md-8">
                                                                <div className="tour-name animated-background mb-2" style={{ height: "40px", width: "100%" }}></div>
                                                                <div className="stars animated-background mb-2" style={{ height: "20px", width: "50%" }}></div>
                                                                <div className="duration animated-background mb-2" style={{ height: "20px", width: "50%" }}></div>
                                                                <div className="descriptions animated-background mb-2" style={{ height: "70px", width: "100%" }}></div>
                                                                <div className="price animated-background mb-2" style={{ height: "20px", width: "50%" }}></div>
                                                                <div className="button animated-background" style={{ height: "40px", width: "30%" }}>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="text-center">Nenhum resultado encontrado</div>
                                    }
                                    <Pagination
                                        totalRows={totalRows}
                                        pageCount={pageCount}
                                        setPageCount={setPageCount}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        selectNumberRows="yes"
                                    />
                                </div>
                            </Row>
                        </div>
                    </div>

                </>
                :
                <>
                </>
            }

            <Footer />
        </>

    )
}

export default TransferList;
