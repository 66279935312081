import { createSlice, configureStore } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    cart: localStorage.getItem('cartC2') === null ? {
      Country: "BR",
      agent: "CPA3120",
      discount: "0",
      foreign: 2,
      language: 1,
      resellerReference: "",
      source: "site",
      totalCart: "0",
      dados: [],
      payments: [],
      cupom:{
        code: "",
        codeName: "",
        type: 0
      }

    }
      :
      JSON.parse(localStorage.getItem('cartC2') || '{}')
  },
  reducers: {

    addItemCart: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cart.dados = [...state.cart.dados, action.payload]
      //if(state.cart.cupom.type === 3) {
        state.cart.cupom = {
          type: 0
        }
      //};
      
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    addAddressCart: (state: any, action) => {
      state.cart.Country = action.payload.country
      state.cart.city = action.payload.city
      state.cart.state = action.payload.state
      state.cart.address = action.payload.address
      state.cart.number = action.payload.number
      state.cart.complement = action.payload.complement
      state.cart.zipCode = action.payload.zipCode
      state.cart.foreign = action.payload.foreign
      saveToLocalStorage(state.cart);
    },

    addPaymentCart: (state: any, action) => {
      state.cart.payments = action.payload
      saveToLocalStorage(state.cart);
    },

    updateDadosCart: (state: any, action) => {
      state.cart.dados = [...action.payload]
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCart: (state: any, action) => {
      state.cart = {...action.payload};
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },
    
    removeItemCart: (state: any, action) => {
      //console.log(state, action)
      state.cart.dados = state.cart.dados.filter((item: any) => item.productCode !== action.payload.productCode);
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },


    removeTotalCart: (state: any, action) => {
      //console.log(state, action);
      state.cart.dados = [];
      state.cart.payments = [];
      state.cart.cupom = {
        type: 0
      };
      state.cart.address = undefined;
      state.cart.city = undefined;
      state.cart.state = undefined;
      state.cart.discount = '0';
      state.cart.tourCode = undefined;
      state.cart.agent = 'CPA3120';
      const tempTotalCart = "0";
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCupomCart: (state: any, action) => {
      // state.cart-= 1
      console.log(action.payload)
      state.cart.cupom = action.payload;

      state.cart.TourCode = {
        code: action.payload.codeName
      }

      if(action.payload.type !== 3){
        state.cart.dados.forEach((element: any) => {
          element.discount = 0;
          element.discountBool = false;
        });
      }

      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeCupomCart: (state: any) => {
      // state.cart-= 1
      state.cart.cupom = {
        type: 0
      };
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart =  tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateMenu: (state: any, action) => {
      localStorage.setItem("menuState", JSON.stringify(action.payload));
    }
  }
})

export const { addItemCart, addAddressCart, addPaymentCart, removeItemCart, updateDadosCart, removeTotalCart, updateCupomCart, removeCupomCart, updateMenu, updateCart } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer
})

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {    
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("cartC2", serialisedState);
    
  } catch (e) {
    console.warn(e);
  }
}

function updateTotalCart(state: any){
  let totalCart = 0;
  try {
    let cartTemp = JSON.stringify(state);
    let cartObj = JSON.parse(cartTemp);
    let priceProduct = 0;
    // eslint-disable-next-line array-callback-return
    cartObj.dados.map((cItem: any, index: any) => {  
      if(cItem.sellingType === 1){
        priceProduct = (
          (Number(cItem.priceGlobalPeople)/*  * Number(cItem.globalPeople) */)
        );
      }else{
        priceProduct = (
          (Number(cItem.adults) * Number(cItem.priceAdults))
          + (Number(cItem.childs) * Number(cItem.priceChilds))
          + (Number(cItem.infants) * Number(cItem.priceInfants))
          + (Number(cItem.elders) * Number(cItem.priceElders))
          + (Number(cItem.student) * Number(cItem.priceStudent))
          + (Number(cItem.globalPeople) * Number(cItem.priceGlobalPeople))
      );
      }
      if(cItem.discountBool === true && cartObj.cupom.type === 3){
        priceProduct = priceProduct - ((priceProduct * (cItem.discount / 100)));
      }
      totalCart = Number(totalCart ) + (priceProduct );      
      
      
    });
    if(cartObj.cupom.type === 2){
      totalCart = Number(totalCart ) - (cartObj.cupom.value);
    }else if(cartObj.cupom.type === 4){
      totalCart = Number(totalCart ) - ((totalCart * (cartObj.cupom.value / 100)));
    }
    
    return totalCart;

    
  } catch (e) {
    console.warn(e);
  }

}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;