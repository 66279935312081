import React from 'react';

import { useTranslation } from "react-i18next";

import './PrivacyTerms.scss';


function PrivacyTerms() {
    const { t } = useTranslation();

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: t('privacy.text') }}></div>
        </>
    );
}

export default PrivacyTerms;