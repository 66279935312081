import React from 'react';
// import TopHeader from '../TopHeader/TopHeader';
import NavHeader from './NavHeader/NavHeader';

export default function Header() {
  if (process.env.NODE_ENV === 'development') {
    return (
      <>
        {/* <TopHeader /> */}
        <header id="main-header">
          <div className="header-content">
            <NavHeader />
          </div>
        </header>
      </>
    );
  } else {
    return (
      <>
        {/* <TopHeader /> */}
        <header id="main-header">
          <div className="header-content">
            <NavHeader />
          </div>
        </header>
      </>
    )
  }
}