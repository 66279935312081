/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import api from "../../../../services/api";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import SetTransfersItem from "../../../../infra/Transfers/SetTransfersItem";

//STYLES
import "./TabSearchs.scss";

//ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link, useHistory } from "react-router-dom";
import SingleCalendarTime from "../../../../components/Calendar/SingleCalendarTime";
import TagManager from "react-gtm-module";
// import SearchSlider from "../../Sliders/SearchSlider/SearchSlider";

export interface prop {
  setSearch?: any;
  search?: any;
}

const TabSearchs: React.FC<prop> = ({
  setSearch, search
}: prop) => {
  let idChannel: number = 2;

  const [t] = useTranslation();

  const transferC2: any = localStorage.getItem("transferC2");
  const transferItemJSON = JSON.parse(transferC2);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tours, setTours] = useState<any>("");
  const [locations, setLocations] = useState<any>("");
  const [isOpen, setIsOpen] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<any>("");
  const [valueSelect, setValueSelect] = useState<any>(null);
  const [num, setNum] = useState<any>(transferItemJSON === null ? 0 : transferItemJSON?.length === 0 ? 1 : transferItemJSON.numPeople);
  const [origin, setOrigin] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.origin);
  const [destiny, setDestiny] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.destine);

  const [latOrigin, setLatOrigin] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.latOrigem);
  const [longOrigin, setLongOrigin] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.lngOrigem);
  const [latDestiny, setLatDestiny] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.latDestino);
  const [longDestiny, setLongDestiny] = useState<any>(transferItemJSON === null ? "" : transferItemJSON.lngDestino);
  const [time, setTime] = useState<any>(transferItemJSON === null ? "" : `${transferItemJSON.date} ${transferItemJSON.time}`);

  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  const [errorOrigin, setErrorOrigin] = useState<string>("");
  const [errorDestiny, setErrorDestiny] = useState<string>("");
  const [errorDate, setErrorDate] = useState<string>("");

  const isTransfer = window.location.pathname;

  const history = useHistory();

  if (localStorage.getItem("transferC2") === null) {
    localStorage.setItem("transferC2", '[]');
  }

  /* Detecta clique fora da div#wrapper para fechar*/
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /* Detecta clique fora da div#wrapper para fechar\\ */

  const addToTransfers = (itemOption: any) => {
    if (time === "" || destiny === undefined || origin === undefined) {
      let erroOrigem: any = document.querySelector('.bg-transfers .col-12:nth-child(1) .input-group');
      let erroDestino: any = document.querySelector('.bg-transfers .col-12:nth-child(2) .input-group');
      let erroDate: any = document.querySelector('.bg-transfers .col-12:nth-child(3) .input-group');

      if (origin === undefined) {
        erroOrigem.classList.toggle("pac-error");

        setTimeout(() => {
          erroOrigem.classList.toggle("pac-error");
        }, 1500);
        /* setErrorOrigin(`${t("tabSearch.error.origin")}`);

        setTimeout(() => {
          setErrorOrigin("");
        }, 2000); */
      }

      if (destiny === undefined) {
        erroDestino.classList.toggle("pac-error");

        setTimeout(() => {
          erroDestino.classList.toggle("pac-error");
        }, 1500);
        /* setErrorDestiny(`${t("tabSearch.error.destiny")}`);

        setTimeout(() => {
          setErrorDestiny("");
        }, 2000); */
      }

      if (time === "") {
        erroDate.classList.toggle("pac-error");

        setTimeout(() => {
          erroDate.classList.toggle("pac-error");
        }, 1500);

        /* setErrorDate(`${t("tabSearch.error.date")}`);

        setTimeout(() => {
          setErrorDate("");
        }, 2000); */
      }

    } else {
      const item = {
        date: time?.split(' ')[0],
        destine: destiny,
        latDestino: latDestiny,
        latOrigem: latOrigin,
        lngDestino: longDestiny,
        lngOrigem: longOrigin,
        numPeople: num,
        origin: origin,
        time: time?.split(' ')[1]
      }

      if (isTransfer === "/transfer") {
        setSearch(item);
      } else {
        SetTransfersItem(item);
      }
    }
  }

  function less() {
    if (num > 1) {
      setNum(num - 1)
    }
  }

  function more() {
    setNum(num + 1)
  }

  function getLocations(data: any) {
    var aux: any = [];

    data.forEach((element: any) => {
      if (!aux.includes(element.eventLocation)) {
        aux.push(element.eventLocation);
      }
    });

    setLocations(aux);
  }

  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

  // const searchTours = useCallback(async () => {
  //   if(searchTerm.length <= 3) return;

  //   const response = await api.post(
  //     `${process.env.REACT_APP_SERVER_URL_API}/Products/SearchByChannel`,
  //     {
  //       term: searchTerm,
  //       channel: idChannel,
  //       lang: lng,
  //       ProductType: 0,
  //     }
  //   );

  //   if (response.data.data !== null) {
  //     setTours(response.data.data);
  //     getLocations(response.data.data);
  //     setIsOpen(true);
  //     // isOpen === false ? setIsOpen(true) : setIsOpen(false);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lng, searchTerm]);

  

  useEffect(() => {
    if(searchTerm.length <= 3) return;
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300); // Ajuste o tempo de debounce conforme necessário
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  
  const searchTours = useCallback(async () => {
    if (!debouncedTerm) return;
  
    const response = await api.post(
      `${process.env.REACT_APP_SERVER_URL_API}/Products/SearchByChannel`,
      {
        term: debouncedTerm,
        channel: idChannel,
        lang: lng,
        ProductType: 0,
      }
    );
  
    if (response.data.data !== null) {
      setTours(response.data.data);
      getLocations(response.data.data);
      setIsOpen(true);
    }
  }, [debouncedTerm, lng, idChannel]);
  
  useEffect(() => {
    searchTours();
  }, [searchTours]);



  const handleClickCity = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.city;
    var typeE = (event.target as HTMLButtonElement).dataset.busca;
    setSearchValue(element);
    const tourSelect = {
      slug: convertToSlug(element?.split(",")[0].split("-")[0]),
      type: typeE
    };

    setValueSelect(tourSelect);
    setIsOpen(false);
  };

  const handleClickTour = (event: React.MouseEvent<HTMLElement>) => {
    var element = (event.target as HTMLButtonElement).dataset.tour;
    var slug = (event.target as HTMLButtonElement).dataset.slug;
    var type = (event.target as HTMLButtonElement).dataset.type;
    var typeE = (event.target as HTMLButtonElement).dataset.busca;
    var codeE =
      (event.target as HTMLButtonElement).dataset.busca === "tour"
        ? (event.target as HTMLButtonElement).dataset.code
        : null;
    setSearchValue(element);
    const tourSelect = {
      slug: slug,
      type: typeE,
      code: codeE,
      typeSearch: type
    };
    setValueSelect(tourSelect);
    setIsOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setSearchTerm(newValue);
  };

  useEffect(() => {
    if (searchValue.length >= 2 && valueSelect === null) {
      getIdChannel();
    } else if (searchValue.length < 2) {
      setIsOpen(false)
      setValueSelect(null)
    } else {
      ///momento do click
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, valueSelect]);


  const handleFocus = () => {
    if (searchValue.length >= 2) {
      // isOpen === false ? setIsOpen(true) : setIsOpen(false);
      setIsOpen(true);
    }
  };

  const convertToSlug = (text: any) => {
    const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    const p = new RegExp(a.split('').join('|'), 'g')
    return text.toString().toLowerCase().trim()
      .replace(p, (c: any) => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[\s\W-]+/g, '-') // Replace spaces, non-word characters and dashes with a single dash (-)
  }

  const handleClickSearch = (city: any) => {
    if (valueSelect?.type === "tour") {
      /* Tratar Ticket */
      
      if (valueSelect?.typeSearch === "TOUR") {
        history.push({
          pathname: `/tour/${valueSelect.slug}`,
          state: valueSelect           
        });
        window.location.href =
          /* process.env.REACT_APP_SERVER_URL */
          window.location.origin + "/tour/" + valueSelect.slug;
      } else if (valueSelect?.typeSearch === "TICKET") {
        window.location.href =
          /* process.env.REACT_APP_SERVER_URL */
          window.location.origin + "/ticket/" + valueSelect.slug;
      }

      /* Tratar Ticket */
    } else if (valueSelect?.type === "city") {
      window.location.href =
        /* process.env.REACT_APP_SERVER_URL */
        window.location.origin + "/atividades/" + valueSelect.slug;
    }
  };

  /*   const handleClickSearch = (city: any) => {
      setSearch(searchValue)
    }; */

  //
  function activeTabChoice(id: any) {
    let element = document.getElementById(id);
    let disable = document.querySelectorAll(".nav-tab-choice");
    let pane = document.querySelectorAll(".tab-pane");
    //Responsividade Medium
    //let tab = document.querySelector('.tab-search-custom');
    let home = document.querySelector('.content-page');
    let tourList = document.querySelector('.tour-list');
    let transfer = document.querySelector('.content-page-transfers');

    disable[0].classList.remove("active");
    disable[1].classList.remove("active");

    element?.classList.add("active");

    if (disable[0].classList.contains('active') === true) {
      pane[0].classList.add("active");
      pane[1].classList.remove("active");
      //Responsividade Mobile
      /* home?.classList.remove('home-medium'); */
      tourList?.classList.remove('tour-list-medium');
      transfer?.classList.remove('content-transfers-medium');
    } else if (disable[1].classList.contains('active') === true) {
      pane[1].classList.add("active");
      pane[0].classList.remove("active");
      //Responsividade Mobile
      /* home?.classList.add('home-medium') */;
      tourList?.classList.add('tour-list-medium');
      transfer?.classList.add('content-transfers-m');
    }
  }

  useEffect(() => {
    if (isTransfer === '/transfer') {
      setNum(search.numPeople || num);
      setOrigin(search.origin);
      setDestiny(search.destine);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);


  const clickEvent = () => { // Search Evento

    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        search_term: searchValue,
      }
    });
  };

  async function getIdChannel() {
    try {
      const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/site`);
      if (data.statusCode === 200) {
        idChannel = data.data.data;
        searchTours();
      }
    } catch (error) { }
  }

  if (tours !== null) {
    if (process.env.REACT_APP_ENVIRONMENT === "c2rio") {
      return (
        <div className="navTabHome bg-tab-home">
          <div className="tab-search-custom py-3">
            <nav className="px-3">
              <div className="row m-0 bg-white">
                <div
                  className="nav nav-tabs col-12 col-xl-7 border-bottom-0 p-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <div className="d-md-block">
                    <div className="row">
                      <div className="col-6">
                        <Link
                          className={isTransfer !== "/transfer" ? "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice active" : "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice"}
                          onClick={() => activeTabChoice("nav-1-tab")}
                          id="nav-1-tab"
                          data-toggle="tab"
                          data-btn="tab"
                          data-linkcontent="nav-content-3"
                          to="#"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                        >
                          <span id="passeio">
                            {t("tabSearch.activity")}
                          </span>
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link
                          className={isTransfer !== "/transfer" ? "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice" : "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice active"}
                          onClick={() => activeTabChoice("nav-2-tab")}
                          id="nav-2-tab"
                          data-toggle="tab"
                          to="#"
                          data-btn="tab"
                          data-linkcontent="nav-content-6"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                        >
                          <span>
                            {t("tabSearch.transfers")}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    {/* <SearchSlider  /> */}
                  </div>
                </div>
              </div>
            </nav>
            {/* <!-- Barra de pesquisas--> */}
            <div
              className="tab-content"
              id="nav-tabContent"
              style={{ borderLeft: "1px solid #fff" }}
            >
              {/* <!--Nav Atividades --> */}
              <div
                //id="wrapper" 
                ref={wrapperRef}
                className={isTransfer !== "/transfer" ? "tab-pane fade show p-3 py-2 py-md-3 active" : "tab-pane fade show p-3 py-2 py-md-3"}
                id="nav-1"
                role="tabpanel"
                aria-labelledby="nav-1-tab"
              >
                <div className="row d-flex bd-highlight">
                  <div className="col col-12 col-md-8 col-lg-10 ">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "search"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>

                      <input
                        type="text"
                        value={searchValue}
                        className="form-control form-control-lg frm-sm pl-5 rounded"
                        data-input="search"
                        autoComplete="off"
                        aria-label="Destino"
                        aria-describedby="tourDestino"
                        id="tourDestino"
                        placeholder={t("tabSearch.searchTour")}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      />

                      <div
                        id="search-menu"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0px",
                          zIndex: 10,
                          backgroundColor: "#fff",
                          padding: "10px",
                          width: "100%",
                          color: "#000",
                        }}
                        className={isOpen === true ? "d-block" : "d-none"}
                      >
                        <div id="search-dataset-allCitys">
                          <h5 className="league-name" style={{ fontSize: "16px" }}>{t("tabSearch.city")}</h5>
                          {locations.length > 0
                            ? locations.map((city: any, index: any) => (
                              <p
                                key={index}
                                onClick={handleClickCity}
                                style={{ cursor: "pointer" }}
                                className="chooice tt-suggestion tt-selectable"
                                data-busca="city"
                                data-input="search"
                                //data-value="{city}"
                                data-city={city}
                              >
                                <span className="result-info city" style={{ fontSize: "18px" }}>{city}</span>
                              </p>
                            ))
                            : ""}
                        </div>
                        <div id="search-dataset-allTourNames">
                          <h5 className="league-name" style={{ fontSize: "16px" }}>{t("tabSearch.tour")}</h5>
                          {tours.length > 0
                            ? tours.map((tours: any, index: any) => (
                              <p
                                key={index}
                                onClick={handleClickTour}
                                style={{ cursor: "pointer" }}
                                className="chooice tt-suggestion tt-selectable"
                                data-busca="tour"
                                data-input="search"
                                data-code={tours.productCode}
                                data-tour={tours.description}
                                data-slug={tours.slug}
                                data-type={tours.productType}
                              >
                                <span className="content-text">
                                  <span className="result-info" style={{ fontSize: "18px" }}>
                                    <strong>
                                      {tours.description}
                                    </strong>
                                  </span>
                                  <div className="price-search">
                                    {" "}
                                    <small>a partir de</small>: <span style={{ fontSize: "18px" }}>R$ {tours.price?.toFixed(2).replace(".", ",")}</span>
                                  </div>
                                </span>
                              </p>
                            ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-3 mt-md-0 mt-lg-0">
                    <button
                      className="btn btn-primary text-white form-control rounded btn-lg"
                      data-btn="search"
                      type="button"
                      id="button-search"
                      onClick={(e: any) => {
                        clickEvent();
                        handleClickSearch(e);
                      }}
                    >
                      <span id="buscar-tour">{t("tabSearch.search")}</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <!--Nav Transfers --> */}
              <div
                className={isTransfer !== "/transfer" ? "bg-transfers tab-pane fade show p-3 py-2 py-md-3" : "bg-transfers tab-pane fade show p-3 py-2 py-md-3 active"}
                id="nav-2"
                role="tabpanel"
                aria-labelledby="nav-2-tab"
              >
                <div className="row d-flex bd-highlight">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="input-group mb-2 mb-lg-0">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "map-marker-alt"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>
                      <AutoComplete
                        options={{
                          types: ["establishment"],
                          fields: ["name"]
                        }}
                        defaultValue={origin}
                        placeholder={t("tabSearch.origin")}
                        apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                        onPlaceSelected={(place) => {
                          setOrigin(place.name);
                          // eslint-disable-next-line no-lone-blocks
                          {
                            geocodeByAddress(`${place.name}`)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLatOrigin(lat);
                                setLongOrigin(lng);
                              }
                              );
                          }
                        }}
                      />

                      <div className="d-flex mt-1 mx-1">
                        <small style={{ color: "red" }}>{errorOrigin}</small>
                      </div>

                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="input-group mb-2 mb-lg-0">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "map-marker-alt"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>

                      <AutoComplete
                        options={{
                          types: ["establishment"],
                          fields: ["name"]
                        }}
                        defaultValue={destiny}
                        placeholder={t("tabSearch.destiny")}
                        apiKey="AIzaSyBv2aZ2YO_aW4PIEmXoxHgxC8Ps8DB0o-s"
                        onPlaceSelected={(place) => {
                          setDestiny(place.name);
                          // eslint-disable-next-line no-lone-blocks
                          {
                            geocodeByAddress(`${place.name}`)
                              .then(results => getLatLng(results[0]))
                              .then(({ lat, lng }) => {
                                setLatDestiny(lat);
                                setLongDestiny(lng);
                              }
                              );
                          }
                        }}
                      />

                      <div className="d-flex mt-1 mx-1">
                        <small style={{ color: "red" }}>{errorDestiny}</small>
                      </div>

                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="input-group mb-2 mb-lg-0">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "clock"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>
                      <SingleCalendarTime setTime={setTime} search={search} />
                    </div>
                    <div className="d-flex mt-1 mx-1">
                      <small style={{ color: "red" }}>{errorDate}</small>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-1">
                    <div className="input-group">
                      <div className="input-group-append">
                        <button className="btn btn-primary btn-less" type="button" onClick={less}>-</button>
                      </div>
                      <div className="icon-male">
                        <FontAwesomeIcon
                          icon={["fal", "male"]}
                          size="1x"
                        />
                      </div>
                      <input type="text" className="form-control" id="num" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2" value={num} disabled />
                      <div className="input-group-append">
                        <button className="btn btn-primary btn-more" type="button" onClick={more}>+</button>
                      </div>
                    </div>
                  </div>

                  <div className="col col-12 col-md-4 col-lg-2 mt-3 mt-lg-0">
                    <button
                      className="btn btn-primary text-white form-control rounded btn-lg"
                      data-btn="search"
                      type="button"
                      id="button-search"
                      onClick={addToTransfers}

                    >
                      <span id="buscar-tour">{t("tabSearch.search")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="navTabHome bg-tab-home">
          <div className="tab-search-custom py-3">
            <nav className="px-3">
              <div className="row m-0 bg-white">
                <div
                  className="nav nav-tabs col-12 col-xl-7 border-bottom-0 p-0"
                  id="nav-tab"
                  role="tablist"
                >
                  <div className="d-md-block">
                    <div className="row">
                      <div className="col-12">
                        <Link
                          className={isTransfer !== "/transfer" ? "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice active" : "nav-item nav-link rounded-top text-center display-6 text-uppercase text-muted nav-tab-choice"}
                          //onClick={() => activeTabChoice("nav-1-tab")}
                          id="nav-1-tab"
                          data-toggle="tab"
                          data-btn="tab"
                          data-linkcontent="nav-content-3"
                          to="#"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                        >
                          <span id="passeio">
                            {t("tabSearch.activity")}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    {/* <SearchSlider  /> */}
                  </div>
                </div>
              </div>
            </nav>
            {/* <!-- Barra de pesquisas--> */}
            <div
              className="tab-content"
              id="nav-tabContent"
              style={{ borderLeft: "1px solid #fff" }}
            >
              {/* <!--Nav Atividades --> */}
              <div
                //id="wrapper" 
                ref={wrapperRef}
                className={isTransfer !== "/transfer" ? "tab-pane fade show p-3 py-2 py-md-3 active" : "tab-pane fade show p-3 py-2 py-md-3"}
                id="nav-1"
                role="tabpanel"
                aria-labelledby="nav-1-tab"
              >
                <div className="row d-flex bd-highlight">
                  <div className="col col-12 col-md-8 col-lg-10 ">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text rounded-left-lg spanIcon"
                          id="homeDestino"
                        >
                          <FontAwesomeIcon
                            icon={["fal", "search"]}
                            size="1x"
                            className="mr-2"
                          />
                        </span>
                      </div>

                      <input
                        type="text"
                        value={searchValue}
                        className="form-control form-control-lg frm-sm pl-5 rounded"
                        data-input="search"
                        autoComplete="off"
                        aria-label="Destino"
                        aria-describedby="tourDestino"
                        id="tourDestino"
                        placeholder={t("tabSearch.searchTour")}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      />

                      <div
                        id="search-menu"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0px",
                          zIndex: 10,
                          backgroundColor: "#fff",
                          padding: "10px",
                          width: "100%",
                          color: "#000",
                        }}
                        className={isOpen === true ? "d-block" : "d-none"}
                      >
                        <div id="search-dataset-allCitys">
                          <h5 className="league-name" style={{ fontSize: "16px" }}>{t("tabSearch.city")}</h5>
                          {locations.length > 0
                            ? locations.map((city: any, index: any) => (
                              <p
                                key={index}
                                onClick={handleClickCity}
                                style={{ cursor: "pointer" }}
                                className="chooice tt-suggestion tt-selectable"
                                data-busca="city"
                                data-input="search"
                                //data-value="{city}"
                                data-city={city}
                              >
                                <span className="result-info city" style={{ fontSize: "18px" }}>{city}</span>
                              </p>
                            ))
                            : ""}
                        </div>
                        <div id="search-dataset-allTourNames">
                          <h5 className="league-name" style={{ fontSize: "16px" }}>{t("tabSearch.tour")}</h5>
                          {tours.length > 0
                            ? tours.map((tours: any, index: any) => (
                              <p
                                key={index}
                                onClick={handleClickTour}
                                style={{ cursor: "pointer" }}
                                className="chooice tt-suggestion tt-selectable"
                                data-busca="tour"
                                data-input="search"
                                data-code={tours.productCode}
                                data-tour={tours.description}
                                data-slug={tours.slug}
                                data-type={tours.productType}
                              >
                                <span className="content-text">
                                  <span className="result-info" style={{ fontSize: "18px" }}>
                                    <strong>
                                      {tours.description}
                                    </strong>
                                  </span>
                                  <div className="price-search">
                                    {" "}
                                    <small>a partir de</small>: <span style={{ fontSize: "18px" }}>R$ {tours.price.toFixed(2).replace(".", ",")}</span>
                                  </div>
                                </span>
                              </p>
                            ))
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mt-3 mt-md-0 mt-lg-0">
                    <button
                      className="btn btn-primary text-white form-control rounded btn-lg"
                      data-btn="search"
                      type="button"
                      id="button-search"
                      onClick={(e: any) => {
                        clickEvent();
                        handleClickSearch(e);
                      }}
                    >
                      <span id="buscar-tour">{t("tabSearch.search")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <></>;
  }
};

export default TabSearchs;
