/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, Key } from "react";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Container } from "react-bootstrap";
import InputRange from 'react-input-range';

import api from "../../../../services/api";

import "./FilterTour.scss";

import StarSolidYellow from "../../../../assets/icons/star-solid-yellow.svg";
import StarSolidGray from "../../../../assets/icons/star-solid-gray.svg";

import 'react-input-range/lib/css/index.css';

export interface prop {
    setTourList?: any;
    search?: any;
    activeCarrousel?: any;
    setActiveCarrousel?: any;
    value?: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setTotalRows: any;
    setLoading: any;
}

const FilterTour: React.FC<prop> = ({
    setTourList, search, activeCarrousel, setActiveCarrousel, value, pageCount, setPageCount, rowsPerPage, setTotalRows, setLoading
}: prop) => {
    const [t] = useTranslation();
    const [allowChangeCategories, setAllowChangeCategories] = useState<any>();
    const [arrTour, setArrTour] = useState<any>([]);
    const [arrStars, setArrStars] = useState<any>([]);
    const [arrTypes, setArrTypes] = useState<any>([]);
    /* const [value, setValue] = useState<any>({ min: 0, max: 2500 }); */
    /* const [value, setValue] = useState<any>(null); */
    let idChannel: number = 2;


    const [tourFirst, setTourFirst] = useState<any>([]);
    const [tourFilterPriceRange, setTourFilterPriceRange] = useState<any>({ min: 0, max: 10000 });
    const [tourFilterType, setTourFilterType] = useState<any>([]);
    const [tourFilterCategorie, setTourFilterCategorie] = useState<any>([]);
    const [tourFilterStars, setTourFilterStars] = useState<any>([]);

    // const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    enum TourCategories {
        "CATBK0826" = t("tourList.cat-1") as any,
        "CATLU5571" = t("tourList.cat-2") as any,
        "CATUH7312" = t("tourList.cat-3") as any,
        "CATQR9923" = t("tourList.cat-4") as any,
        "CATFR0970" = t("tourList.cat-5") as any,
        "CATVX8164" = t("tourList.cat-6") as any,
        "CATGG6755" = "Conhecimento" as any,
        "CATES9338" = "Entretenimento" as any,
        "CATBJ3647" = "Náutico" as any,
        "CATPO0133" = "Saúde e Bem-Estar" as any,
    }

    enum AuxCat {  ///usado para corrigir o bug no click do carrousel
        "Arte, Design e Moda" = 0 as any,
        "História e Cultura" = 1 as any,
        "Estilo de Vida e Celebrações" = 2 as any,
        "Natureza e Impacto Social" = 3 as any,
        "Viagem" = 4 as any,
        "Ar Livre e Aventura" = 5 as any,

    }

    const getTourList = useCallback(async (pageOne: any = false) => {
        
        setLoading(true);
        /* const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR'; */
        const filter = async () => {
            try {
                const res = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchByChannel`,
                    {
                        "page": pageOne === true ? 1 : pageCount,
                        "rowsPerPage": rowsPerPage,
                        "term": search,
                        "channel": idChannel,
                        "lang": i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR',
                        "productType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
                        "categories": tourFilterCategorie,
                        "stars": tourFilterStars !== null ? tourFilterStars : [],
                        "priceStart": /* tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min :  */1,
                        "priceEnd": /* tourFilterPriceRange.max */10000
                    }
                );
                if (res.status !== 400) {
                    setLoading(false);
                    if (res.data.data !== null) {
                        setTotalRows(res.data.data.rowsCount)
                        setTourList(res.data.data.rows);
                        if (tourFirst.length === 0 || allowChangeCategories !== i18next.language) {
                            setAllowChangeCategories(i18next.language);
                            var aux: any = []
                            res.data.data.categories.forEach((elem: any) => {
                                aux.push(elem);

                            });

                            setTourFirst(aux);
                        }

                        /* if(isFirstRender === true){
                            setIsFirstRender(false);

                            setTourFilterPriceRange({ min: 0, max: res.data.data.maxPrice })
                        } */
                    } else {
                        setTourList(null);
                    }
                }
            } catch (error) {
                setLoading(false);
                
            }
        }
        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/site`);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    if (search !== null) {
                        filter();
                    }
                }
            } catch (error) { }
        }

        getIdChannel();

        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourFilterCategorie, tourFilterType, tourFilterStars, pageCount, rowsPerPage, i18next.language])

    useEffect(() => {
        setPageCount(1);
        if (search != null) {
            getTourList(true);
        }
    }, [tourFilterCategorie, tourFilterType, tourFilterStars, i18next.language]);

    useEffect(() => {
        //if (pageCount !== 1) {
            getTourList();
        //}
    }, [pageCount, rowsPerPage]);

    function handleRangeChange(e: any) {
        setTourFilterPriceRange(e);
    }

    /* Botão Filtrar Preço */
    async function handlePrice() {
        const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

        try {
            const res = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchByChannel`,
                {
                    "page": 1,
                    "rowsPerPage": 10,
                    "term": search,
                    "channel": 2,
                    "lang": lng,
                    "ProductType": tourFilterType.length > 1 || tourFilterType.length === 0 ? 0 : tourFilterType[0],
                    "categories": tourFilterCategorie,
                    "stars": tourFilterStars !== null ? tourFilterStars : [],
                    "priceStart": tourFilterPriceRange.min !== 0 ? tourFilterPriceRange.min : 1,
                    "priceEnd": tourFilterPriceRange.max
                }
            );
            if (res.status !== 400) {
                if (res.data.data !== null) {
                    setTourList(res.data.data.rows);
                    /* console.log(arrTour) */
                    if (tourFirst.length === 0) {
                        var aux: any = []
                        res.data.data.categories.forEach((elem: any) => {
                            aux.push(elem);

                        });

                        setTourFirst(aux);
                    }
                } else {
                    setTourList(false);
                    /* console.log(res.data) */
                }
            }
        } catch (error) {
        }
    }
    /* END - Botão Filtrar Preço */

    function handleClickType(event: any, filterValue: any = null) {
        var value: any = event.target.value;

        if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
            value = filterValue;
        }

        let types = [];

        if (arrTypes.includes(value) === true) {
            for (let i = 0; i < arrTypes.length; i++) {
                if (arrTypes[i] !== value) {
                    types.push(arrTypes[i]);
                }
            }

            setTourFilterType(types);
            setArrTypes(types);
        } else {
            setTourFilterType([...arrTypes, value]);
            setArrTypes([...arrTypes, value]);
        }
    }



    function handleClickTour(event: any, filterValue: any = null) {
        var value: any = event.target.value;


        var input = event.target.id;  ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox
        var activeAux = activeCarrousel;      ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox

        if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
            value = filterValue;
            input = Number(input) + 1;

            filterValue = TourCategories[filterValue];
            filterValue = AuxCat[filterValue]

            activeAux[filterValue] = !activeAux[filterValue];
        }

        activeAux[AuxCat[input]] = !activeAux[AuxCat[input]];       ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox
        setActiveCarrousel(activeAux);        ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox

        let tour: any = [];

        if (arrTour.includes(value) === true) {                    ///remove da lista caso ja exista
            for (let i = 0; i < arrTour.length; i++) {
                if (arrTour[i] !== value) {
                    tour.push(arrTour[i]);
                }
            }

            setTourFilterCategorie(tour);
            setArrTour(tour);
        } else {                                                   ///adiciona na lista
            setTourFilterCategorie([...arrTour, value]);
            setArrTour([...arrTour, value]);
        }
    }

    useEffect(() => {
        setTourFilterPriceRange(value)
    }, [value])

    function handleClickStars(event: any, filterValue: any = null) {
        var value: any = event.target.value;
        console.log(event)
        if (filterValue !== null) { /// somente quando o click é feito no "x" da lista de filtros selecionados
            value = filterValue;
        }

        let stars = [];

        if (arrStars.includes(value) === true) {
            for (let i = 0; i < arrStars.length; i++) {
                if (arrStars[i] !== value) {
                    stars.push(arrStars[i]);
                }
            }

            setTourFilterStars(stars);
            setArrStars(stars);
        } else {
            setTourFilterStars([...arrStars, value]);
            setArrStars([...arrStars, value]);
        }
    }

    function clearFilters() {
        setTourFilterType([]);
        setArrTypes([]);
        setTourFilterCategorie([]);
        setArrTour([]);
        setTourFilterStars([]);
        setArrStars([]);
        setActiveCarrousel([false, false, false, false, false, false]);
    }

    return (
        <>
            <Container className="pt-1 p-4" fluid>
                <div className="mt-4 mb-5 d-none d-lg-block">
                    <h5
                        className="mb-4 title-filter"
                    >
                        {t("tourList.filter")} asasasas
                    </h5>

                    {(tourFilterType.length > 0)
                        ? tourFilterType.map((filter: any, index: Key) => (
                            <div key={index} className="d-flex justify-content-between">
                                <p>{filter === "1" ? "Tour" : filter === "4" ? "Tickets" : ''}</p>
                                <p onClick={(e) => handleClickType(e, filter)} style={{ cursor: "pointer" }}>x</p>
                            </div>
                        ))
                        : ''
                    }

                    {tourFilterCategorie.length > 0
                        ? tourFilterCategorie.map((filter: any, index: Key) => (
                            <div key={index} className="d-flex justify-content-between">
                                <p>{TourCategories[filter]}</p>
                                <p onClick={(e) => handleClickTour(e, filter)} id={"close-" + index/* AuxTourClose[filter] */} style={{ cursor: "pointer" }}>x</p>
                            </div>
                        ))
                        : ''
                    }

                    {tourFilterStars.length > 0
                        ? tourFilterStars.map((filter: any, index: Key) => (
                            <div key={index} className="d-flex justify-content-between">
                                <p>{filter} {t("tourList.stars")}</p>
                                <p onClick={(e) => handleClickStars(e, filter)} style={{ cursor: "pointer" }}>x</p>
                            </div>
                        ))
                        : ''
                    }

                    {tourFilterCategorie.length > 0 || tourFilterStars.length > 0 || tourFilterType.length > 0
                        ? <span className="clear-filter" onClick={clearFilters}>{t("tourList.clear")}</span>
                        : ''
                    }


                </div>
                <div className="mt-4 mb-5">
                    <h5
                        className="mb-4 title-filter"
                    >
                        {t("tourList.prices")}
                    </h5>
                    <div className="d-flex m-auto">
                        {value
                            ?
                            <InputRange
                                maxValue={value.max}
                                minValue={value.min}
                                value={tourFilterPriceRange}
                                step={50}
                                onChange={handleRangeChange}
                            />
                            : ''
                        }
                    </div>
                    <div style={{ marginTop: "30px" }}>
                        <button onClick={handlePrice} className="btn btn-primary" style={{ borderRadius: "0", width: "96%" }}>{t("tourList.btnFilter")}</button>
                    </div>
                </div>
                <div className="mb-5">
                    <h5
                        className="mb-4 title-filter"
                    >
                        {t("tourList.type")}
                    </h5>
                    <div className="d-flex flex-column">
                        <div className="check-cat">
                            <input
                                checked={tourFilterType.includes("1")}
                                type="checkbox"
                                name="type"
                                id="type-1"
                                value={1}
                                onChange={handleClickType}
                            />
                            <label htmlFor="type-1">{t("tourList.tour")}</label>
                        </div>
                        <div className="check-cat">
                            <input
                                checked={tourFilterType.includes("4")}
                                type="checkbox"
                                name="type"
                                id="type-2"
                                value={4}
                                onChange={handleClickType}
                            />
                            <label htmlFor="type-2">{t("tourList.tickets")}</label>
                        </div>
                    </div>
                </div>
                <div className="mb-5">
                    <h5
                        className="mb-4 title-filter"
                    >
                        {t("tourList.tours")}
                    </h5>
                    <div id="tours-cat" className="d-flex flex-column">
                        {tourFirst.length > 0
                            ? tourFirst.map((tourCode: any, index: Key) => (
                                <div className="check-cat" key={index}>
                                    <input
                                        checked={tourFilterCategorie.includes(tourCode.categorieCode)}
                                        type="checkbox"
                                        name={tourCode.categorieName}
                                        id={tourCode.categorieName}
                                        value={tourCode.categorieCode}
                                        onChange={handleClickTour}
                                    />
                                    <label htmlFor={tourCode.categorieName}>{tourCode.categorieName}</label>
                                </div>
                            ))
                            : ''
                        }
                    </div>
                    <div className="mt-4 mb-5">
                        <h5
                            className="mb-4 title-filter"
                            style={{
                                color: "#163264",
                                //textTransform: "uppercase",
                                fontSize: "1.25rem",
                            }}
                        >
                            {t("tourList.aval")}
                        </h5>
                        <div className="d-flex flex-column">
                            <div className="check-cat">
                                <input
                                    checked={tourFilterStars.includes("5")}
                                    onChange={handleClickStars}
                                    type="checkbox"
                                    name="aval-1"
                                    id="aval-1"
                                    value={5}
                                /* onClick={handleClickStars} */
                                />
                                <label htmlFor="aval-1">
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                </label>
                            </div>
                            <div className="check-cat">
                                <input
                                    checked={tourFilterStars.includes("4")}
                                    type="checkbox"
                                    name="aval-2"
                                    id="aval-2"
                                    value={4}
                                    onChange={handleClickStars}
                                />
                                <label htmlFor="aval-2">
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                </label>
                            </div>
                            <div className="check-cat">
                                <input
                                    checked={tourFilterStars.includes("3")}
                                    type="checkbox"
                                    name="aval-3"
                                    id="aval-3"
                                    value={3}
                                    onChange={handleClickStars}
                                />
                                <label htmlFor="aval-3">
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                </label>
                            </div>
                            <div className="check-cat">
                                <input
                                    checked={tourFilterStars.includes("2")}
                                    type="checkbox"
                                    name="aval-4"
                                    id="aval-4"
                                    value={2}
                                    onChange={handleClickStars}
                                />
                                <label htmlFor="aval-4">
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                </label>
                            </div>
                            <div className="check-cat">
                                <input
                                    checked={tourFilterStars.includes("1")}
                                    type="checkbox"
                                    name="aval-5"
                                    id="aval-5"
                                    value={1}
                                    onChange={handleClickStars}
                                />
                                <label htmlFor="aval-5">
                                    <img src={StarSolidYellow} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                    <img src={StarSolidGray} alt="" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default FilterTour;
