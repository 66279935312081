import React, { Component } from "react";
import Slider from "react-slick";
import i18next from "i18next";

//import { Link as LinkRoll } from "react-scroll";

import { Container, Row } from "react-bootstrap";

import BannerAngraDesktopPt from "../../../assets/img/banners-angra/banner-angra.png";
import BannerAngraDesktopEn from "../../../assets/img/banners-angra/banner-angra.png";
import BannerAngraDesktopEs from "../../../assets/img/banners-angra/banner-angra.png";

import BannerAngraMobilePt from "../../../assets/img/banners-angra/banner-angra.png";
import BannerAngraMobileEn from "../../../assets/img/banners-angra/banner-angra.png";
import BannerAngraMobileEs from "../../../assets/img/banners-angra/banner-angra.png";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BannerHomeAngra.scss";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from "react-router-dom";

export default class BannerHomeAngra extends Component {
  render() {
    const settings = {
      /* dots: true, */
      draggable: false,
      useTransform: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 5500,
      cssEase: "ease-out",
    };

    const typeDevice = window.innerWidth < 480 ? "mobile" : "desktop";

    return (
      <div className="bg-main-banner">
        <Slider {...settings}>
          <div /* to={"/transfer"} */>
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
              {/* <LinkRoll
                activeClass="active"
                to="main-product"
                spy={true}
                smooth={true}
                offset={-250}
                duration={500}
              > */}
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? BannerAngraDesktopPt
                          : i18next.language === "en"
                            ? BannerAngraDesktopEn
                            : i18next.language === "es"
                              ? BannerAngraDesktopEs
                              : BannerAngraDesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? BannerAngraMobilePt
                          : i18next.language === "en"
                            ? BannerAngraMobileEn
                            : i18next.language === "es"
                              ? BannerAngraMobileEs
                              : BannerAngraMobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
                {/* </LinkRoll> */}
              </Container>
            </Container>
          </div>
        </Slider>
      </div>
    );
  }
}
