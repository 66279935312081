import Button from "@restart/ui/esm/Button";
import React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import FormLogin from '../../Authenticated/FormLogin';
//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function FilterModal(props: any) {
    
    const [modalShow, setModalShow] = React.useState(props.open);

    function handleCheck(e:any) {
        let checked:any = document.querySelector('#chackPolice')
        checked.checked = true;
        checked.value = true;
        setModalShow(false)
    }

    function handleClose () {
        let checked:any = document.querySelector('#chackPolice')
        checked.checked = false;
        checked.value = false;
        setModalShow(false);
    }


    return (
        <div>
            <Modal
                {...props}
                className="modal-custom modalAuth"
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered={props.center === "yes" ? true : false}
            >
                <>
                    <Modal.Header closeButton>
                        {props.header ? <h4>{props.header}</h4> : ""}
                    </Modal.Header>
                    <Modal.Body className={props.classBody ? props.classBody : ""}>
                        {props.children}
                    </Modal.Body>

                    {
                        props.footer === "yes"
                            ?
                            <Modal.Footer>
                                <Button className="btn btn-outline-primary" onClick={handleClose}>{props.close ? props.close : ""}</Button>
                                <Button className="btn btn-primary" onClick={handleCheck}>{props.save ? props.save : ""}</Button>
                            </Modal.Footer>
                            :
                            ""
                    }
                </>
            </Modal>


            <div className={props.class ? props.class : ""}>
                {
                    props.type === "privacy"
                        ?
                        <span onClick={() => setModalShow(true)} className="privacy-check">
                            {props.name}
                        </span>
                        :
                        <Button className="btn" onClick={() => setModalShow(true)} style={{ boxShadow: "none" }}>
                            {
                                <FontAwesomeIcon
                                    icon={["fal", props.name]}
                                    size="2x"
                                    style={{ margin: "0", color: "#163264", fontSize: "20px" }}
                                />
                            }
                        </Button>
                }

            </div>
        </div>
    );
};

export default FilterModal;
