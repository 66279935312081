import React from 'react';

import { useTranslation } from "react-i18next";

import './TermsUse.scss';

function TermsUse() {
  const { t } = useTranslation();

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: t('termsOfUse.text') }}></div>
    </>
  );
}

export default TermsUse;