import React, { useState } from "react";

import { Row, Col } from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";
//import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

export interface propForm {
  action: any;
  back: any;
  choice: any;
  supplierControlObject: any;
}

const FormDocuments: React.FC<propForm> = React.memo(
  ({ action, back, choice, supplierControlObject }: propForm) => {
    const { t } = useTranslation();
    const [cpfAg, setCpfAg] = useState<any>(
      supplierControlObject ? supplierControlObject.cpfBase64 : ""
    );
    const [cpfName, setCpfName] = useState<any>(
      supplierControlObject ? supplierControlObject.cpfBase64Name : ""
    );

    const [rgAg, setRgAg] = useState<any>(
      supplierControlObject ? supplierControlObject.rgBase64 : ""
    );
    const [rgName, setRgName] = useState<any>(
      supplierControlObject ? supplierControlObject.rgBase64Name : ""
    );

    const [socialContract, setSocialContract] = useState<any>(
      supplierControlObject ? supplierControlObject.contractBase64 : ""
    );
    const [socialContractName, setSocialContractName] = useState<any>(
      supplierControlObject ? supplierControlObject.contractBase64Name : ""
    );

    const [cnpjAg, setCnpjAg] = useState<any>(
      supplierControlObject ? supplierControlObject.cnpjAgenciaTurBase64 : ""
    ); //CNPJ da agencia de turismo
    const [cnpjName, setCnpjName] = useState<any>(
      supplierControlObject
        ? supplierControlObject.cnpjAgenciaTurBase64Name
        : ""
    );

    const [im, setIm] = useState<any>(
      supplierControlObject
        ? supplierControlObject.inscricaoMunicipalBase64
        : ""
    ); //inscrição municipal
    const [imName, setImName] = useState<any>(
      supplierControlObject
        ? supplierControlObject.inscricaoMunicipalBase64Name
        : ""
    );

    const [cn, setCn] = useState<any>(
      supplierControlObject ? supplierControlObject.certidaoNegativaBase64 : ""
    ); //certidão negativa
    const [cnName, setCnName] = useState<any>(
      supplierControlObject
        ? supplierControlObject.certidaoNegativaBase64Name
        : ""
    );

    const [rmt, setRmt] = useState<any>(
      supplierControlObject ? supplierControlObject.registroMinTurBase64 : ""
    ); //registro municipal de turismo
    const [rmtName, setRmtName] = useState<any>(
      supplierControlObject
        ? supplierControlObject.registroMinTurBase64Name
        : ""
    );

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      /* defaultValues: {
            rgBase64: supplierControlObject ? supplierControlObject.rgBase64 : '',
            cpfBase64: supplierControlObject ? supplierControlObject.cpfBase64 : '',
            reprRGBase64: supplierControlObject ? supplierControlObject.reprRGBase64 : '',
            socialContractBase64: supplierControlObject ? supplierControlObject.socialContractBase64 : '',
        }, */
    });

    const validated = false;

    /* Pessoa Física */
    const cpfHandler = (e: any) => {
      if (e.target.files[0].type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setCpfAg(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setCpfName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    const rgHandler = (e: any) => {
      if (e.target.files[0]?.type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setRgAg(reader.result);              
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setRgName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };
    
    /* END - Pessoa Física */

    /* Pessoa Jurídica */

    const socialContractHandler = (e: any) => {
      if (e.target.files[0].type === "application/pdf") {
        if (e.target.files[0].size <= 6291456) {
          //6MB -> 6291456
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setSocialContract(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setSocialContractName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    const cnpjHandler = (e: any) => {
      if (e.target.files[0].type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setCnpjAg(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setCnpjName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    const imHandler = (e: any) => {
      if (e.target.files[0].type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setIm(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setImName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    const cnHandler = (e: any) => {
      if (e.target.files[0].type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setCn(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setCnName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    const rmtHandler = (e: any) => {
      if (e.target.files[0].type === "image/jpeg") {
        if (e.target.files[0].size <= 5242880) {
          //5MB -> 5242880
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2) {
              setRmt(reader.result);
            }
          };
          reader.readAsDataURL(e.target.files[0]);
          setRmtName(e.target.files[0].name);
        } else {
          alert(t("affiliates.documentErrorSize"));
        }
      } else {
        alert(t("affiliates.documentErrorRightFormat"));
      }
    };

    /* END - Pessoa Jurídica */

    const onSubmit = (data: any) => {
      data.rgBase64 = rgAg === undefined ? "" : rgAg;
      data.rgBase64Name = rgAg === undefined ? "" : rgName;

      data.cpfBase64 = cpfAg === undefined ? "" : cpfAg;
      data.cpfBase64Name = cpfAg === undefined ? "" : cpfName;

      data.cnpjAgenciaTurBase64 = cnpjAg === undefined ? "" : cnpjAg;
      data.cnpjAgenciaTurBase64Name = cnpjAg === undefined ? "" : cnpjName;

      data.inscricaoMunicipalBase64 = im === undefined ? "" : im;
      data.inscricaoMunicipalBase64Name = im === undefined ? "" : imName;

      data.registroMinTurBase64 = rmt === undefined ? "" : rmt;
      data.registroMinTurBase64Name = rmt === undefined ? "" : rmtName;

      data.certidaoNegativaBase64 = cn === undefined ? "" : cn;
      data.certidaoNegativaBase64Name = cn === undefined ? "" : cnName;

      data.contractBase64 = socialContract === undefined ? "" : socialContract;
      data.contractBase64Name =
        socialContract === undefined ? "" : socialContractName;

      // if (supplierControlObject.compType === 2) {
      //   if (data.rgBase64 !== "" || data.cpfBase64 !== "") {
      //     action(data);
      //   } else {
      //     //alert(t("affiliates.documentErrorFillField"));
      //   }
      // } else {
      //   if (data.contractBase64 !== "") {
      //     action(data);
      //   } else {
      //     //alert(t("affiliates.documentErrorFillField"));
      //   }
      // }
      
      action(data);
    };
    console.log(supplierControlObject);
    return (
      <>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="false"
        >
          {supplierControlObject.compType === 2 ? (
            <>
              <Row>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>{t("affiliates.formAttachRg")}</Form.Label>
                  <br />
                  <Controller
                    control={control}
                    name="rgBase64"
                    //rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o contrato social.' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        label="Anexar RG (no formato JPEG)"
                        aria-invalid={errors?.rgBase64 ? "true" : ""}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        id="rgBase64"
                        accept=".jpg"
                        onChange={rgHandler}
                        //required
                      />
                    )}
                  />
                  <label className="btn-file" htmlFor="rgBase64">
                    {t("affiliates.formAttach")}
                  </label>
                  <small>
                    {supplierControlObject.rgName !== undefined
                      ? supplierControlObject.rgName
                      : rgName}
                  </small>
                  {/* <ErrorMessage
                                errors={errors}
                                name="socialContractBase64"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            /> */}
                </Form.Group>
                <Form.Group as={Col} md={6} className="mb-3">
                  <Form.Label>{t("affiliates.formAttachCpf")}</Form.Label>
                  <br />
                  <Controller
                    control={control}
                    name="cpfBase64"
                    /* rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o RG do representante.' } }} */
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        label="Anexar CPF (no formato JPEG)"
                        aria-invalid={errors?.cpfBase64 ? "true" : ""}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        id="cpfBase64"
                        accept=".jpg"
                        onChange={cpfHandler}
                        //required
                      />
                    )}
                  />
                  <label className="btn-file" htmlFor="cpfBase64">
                    {t("affiliates.formAttach")}
                  </label>
                  <small>
                    {supplierControlObject.cpfName !== undefined
                      ? supplierControlObject.cpfName
                      : cpfName}
                  </small>
                  {/* <ErrorMessage
                                errors={errors}
                                name="rgReprBase64"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            /> */}
                </Form.Group>
              </Row>
            </>
          ) : (
            <>
              <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    {t("affiliates.formAttachRepresentativeRg")}
                  </Form.Label>
                  <br />
                  <Controller
                    control={control}
                    name="rgBase64"
                    //rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o contrato social.' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        label="Anexar RG (no formato JPEG) do Representante"
                        aria-invalid={errors?.rgBase64 ? "true" : ""}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        id="rgBase64"
                        accept=".jpg"
                        onChange={rgHandler}
                        //required
                      />
                    )}
                  />
                  <label className="btn-file" htmlFor="rgBase64">
                    {t("affiliates.formAttach")}
                  </label>
                  <small>
                    {supplierControlObject.rgName !== undefined
                      ? supplierControlObject.rgName
                      : rgName}
                  </small>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>
                    {t("affiliates.formAttachSocialContract")}
                  </Form.Label>
                  <br />
                  <Controller
                    control={control}
                    name="contractBase64"
                    /* rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o RG do representante.' } }} */
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        label="Anexar contrato social em PDF"
                        aria-invalid={
                          errors?.socialContractBase64 ? "true" : ""
                        }
                        fullWidth
                        variant="standard"
                        margin="normal"
                        id="contractBase64"
                        accept=".pdf"
                        onChange={socialContractHandler}
                        //required
                      />
                    )}
                  />
                  <label className="btn-file" htmlFor="contractBase64">
                    {t("affiliates.formAttach")}
                  </label>
                  <small>
                    {supplierControlObject.socialContractName !== undefined
                      ? supplierControlObject.socialContractName
                      : socialContractName}
                  </small>
                </Form.Group>
              </Row>
            </>
          )}

          <Row className="mb-3">
            <Form.Group as={Col} md={6}>
              <Form.Label>
                {t("affiliates.formAttachCND")}                
              </Form.Label>
              <br />
              <Controller
                control={control}
                name="certidaoNegativaBase64"
                //rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o contrato social.' } }}
                render={({ field }: any) => (
                  <Form.Control
                    type="file"
                    {...field}
                    label="Anexar Certidão Negativa de Débito - CND (no formato JPEG)"
                    aria-invalid={errors?.certidaoNegativaBase64 ? "true" : ""}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    id="certidaoNegativaBase64"
                    accept=".jpg"
                    onChange={cnHandler}
                    //required
                  />
                )}
              />
              <label className="btn-file" htmlFor="certidaoNegativaBase64">
                {t("affiliates.formAttach")}
              </label>
              <small>
                {supplierControlObject.cnName !== undefined
                  ? supplierControlObject.cnName
                  : cnName}
              </small>
            </Form.Group>

            
            <Form.Group as={Col} md={6}>
              <Form.Label>
                {t("affiliates.formAttachIM")}
              </Form.Label>
              <br />
              <Controller
                control={control}
                name="inscricaoMunicipalBase64"
                /* rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o CNPJ da agência.' } }} */
                render={({ field }: any) => (
                  <Form.Control
                    type="file"
                    {...field}
                    label="Anexar Inscrição Municipal (no formato JPEG)"
                    aria-invalid={
                      errors?.inscricaoMunicipalBase64 ? "true" : ""
                    }
                    fullWidth
                    variant="standard"
                    margin="normal"
                    id="inscricaoMunicipalBase64"
                    accept=".jpg"
                    onChange={imHandler}
                    //required
                  />
                )}
              />
              <label className="btn-file" htmlFor="inscricaoMunicipalBase64">
                {t("affiliates.formAttach")}
              </label>
              <small>
                {supplierControlObject.imName !==
                undefined
                  ? supplierControlObject.imName
                  : imName}
              </small>
            </Form.Group>
          </Row>

          
          <Row className="mb-3">
            <Form.Group as={Col} md={6}>
              <Form.Label>
                {t("affiliates.formAttachCNPJ")}
              </Form.Label>
              <br />
              <Controller
                control={control}
                name="cnpjAgenciaTurBase64"
                //rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o contrato social.' } }}
                render={({ field }: any) => (
                  <Form.Control
                    type="file"
                    {...field}
                    label="Anexar CNPJ (no formato JPEG) da agência de turismo"
                    aria-invalid={errors?.cnpjAgenciaTurBase64 ? "true" : ""}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    id="cnpjAgenciaTurBase64"
                    accept=".jpg"
                    onChange={cnpjHandler}
                    //required
                  />
                )}
              />
              <label className="btn-file" htmlFor="cnpjAgenciaTurBase64">
                {t("affiliates.formAttach")}
              </label>
              <small>
                {supplierControlObject.cnpjName !== undefined
                  ? supplierControlObject.cnpjName
                  : cnpjName}
              </small>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>
                {t("affiliates.formAttachRMT")}
              </Form.Label>
              <br />
              <Controller
                control={control}
                name="registroMinTurBase64"
                /* rules={{ required: { value: hasContractControl.value, message: 'Por favor, anexar o CNPJ da agência.' } }} */
                render={({ field }: any) => (
                  <Form.Control
                    type="file"
                    {...field}
                    label="Anexar Registro Municipal (no formato JPEG)"
                    aria-invalid={errors?.registroMinTurBase64 ? "true" : ""}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    id="registroMinTurBase64"
                    accept=".jpg"
                    onChange={rmtHandler}
                    //required
                  />
                )}
              />
              <label className="btn-file" htmlFor="registroMinTurBase64">
                {t("affiliates.formAttach")}
              </label>
              <small>
                {supplierControlObject.rmtName !== undefined
                  ? supplierControlObject.rmtName
                  : rmtName}
              </small>
            </Form.Group>
          </Row>

          <Row className="d-flex justify-content-end pt-5">
            <Col md={3}>
              <button
                className="btn btn-outline-primary mb-3 mb-md-0"
                onClick={() => back(1)}
              >
                {t("affiliates.formBack")}
              </button>
            </Col>
            <Col md={3}>
              <button type="submit" className="btn btn-primary">
                {t("affiliates.formContinue")}
              </button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
);

export default FormDocuments;
