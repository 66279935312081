import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

//import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsLabel: any,
    propsErrors: any,
};


const GetBanks: React.FC<propPlus> = ({
    propsField, propsLabel, propsErrors
}: propPlus) => {
    //const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listBanks() {
            try {
                const { data } = await api.get(`/DropDown/GetBanks`, config);
                if (data.status !== 400) {
                    setInfo(data.data)
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    //window.location.href = window.location.origin + '/';
                }

            }
        }
        listBanks();
    }, []);

    if (info !== null) {
        return (
            <>
                <div className={propsErrors.codBank !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused ? '#f6a18e' : '#dee2e6',
                              boxShadow: state.isFocused && " 0 0 0 0.25rem rgba(236, 66, 28, 0.25)",
                              height: "47px",
                            }),
                          }}
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        {...propsField}
                        aria-invalid={propsErrors?.codBank ? "true" : ""}
                        label={propsLabel}
                        as="select"
                        variant="standard"
                        margin="normal"
                        placeholder="Selecione"
                        options={Array.from(info)}
                        getOptionLabel={(option: any) => `${option.description}`}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )

    }
}

export default GetBanks;