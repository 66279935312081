import React, { useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';


import './NewFooter.scss';

import logoC2rio from '../../assets/img/footer/Logo-c2rio.svg';
import logoWhatsapp from '../../assets/img/footer/whatsapp-logo.svg';
import logoGrupoIter from '../../assets/img/footer/grupo-iter.svg';
import logoAbav from '../../assets/img/footer/logo-abav.svg';
import logoCadastur from '../../assets/img/footer/logo-cadastur.png';
import logoSkal from '../../assets/img/footer/logo-skal.png';
import logoVisitRio from '../../assets/img/footer/visit-rio.png';
import logoGSafe from '../../assets/img/footer/google-safe.png';
import Elo from "../../assets/icons/elo-logo.png";
import Hiper from "../../assets/icons/Hipercard-logo.png";
import TripAdvisor from "../../assets/icons/svg/tripadvisor.svg";

import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Newsletter from './components/Newsletter/Newsletter';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RdStationForm from './components/RDStationForm/RDStationForm';

const Footer: React.FC = () => {
  const [t] = useTranslation();
  const postUrl:string = `https://c2rio.us13.list-manage.com/subscribe/post?u=1ab6480bde31e838a608aec2c&amp;id=d9d387ec11`;
  


  const tags: any = ["acessibilidade", "AquaRio", "BioParque", "Circuito", "Dia das Crianças", "Festival", "Museu do Flamengo", "passeios", "Primavera", "Rio com Chuva",
"Rio com Crianças", "rio de janeiro", "Rock in Rio", "Roteiro", "Spring Break"]

  return (
    <footer className='bg-footer-blue-400'>
      <div className='container'>
        <div className="content-top">
          <div className="w-100 pb-4 logo-c2rio">
            <img width={154} src={logoC2rio} alt="Logo C2Rio" className='logo' />
          </div>

          <Row className='gap-4 gap-sm-4 gap-md-0'>
            <Col sm={12} md={3} >
              <div >
                <p className="text-center text-md-start ">CNPJ: 05.873.416/0001-38</p>
                <p className="text-center text-md-start "><strong>{t('newfooter.pointOfSale')}</strong></p>

                <p className="text-center text-md-start ">
                  <a href="https://g.page/r/CREmdIeo46dhEBM/review"
                    target='_blank'>
                    <span>
                      <strong>Hotel Hilton Copacabana</strong><br />
                      Av. Princesa Isabel 10 – Copacabana, Rio de Janeiro – RJ, 22011-010<br />
                      {t('newfooter.hiltonCopaTime')}
                    </span>
                  </a>
                </p>

                <p className="text-center text-md-start ">
                  <a
                    href="https://g.page/r/CaeyGmO_AsiKEBI/review"
                    target='_blank'>
                    <span>
                      <strong>Hotel Hilton Barra</strong><br />
                      Av. Embaixador Abelardo Bueno 1430 – Barra da Tijuca, Rio de Janeiro – RJ, 22775-040<br />
                      {t('newfooter.hiltonBarraTime')}
                    </span>
                  </a>
                </p>

                <p className="text-center text-md-start ">
                  <a href="https://g.page/r/CdwjVWgnX_dmEBM/review" target='_blank'>
                    <span>
                      <strong>Marina da Glória</strong><br />
                      Av. Infante Dom Henrique S/N – Glória, Rio de Janeiro – RJ, 20021-140<br />
                      {t('newfooter.marinaTime')}
                    </span>
                  </a>
                </p>
                <p className="text-center text-md-start ">
                  <a href="https://g.page/r/CdKV7tNLLkwgEBM/review" target='_blank'>
                    <span>
                      <strong>Office</strong><br />
                      Praia de Botafogo 501 – Botafogo, Rio de Janeiro – RJ, 22250-040
                    </span>
                  </a>
                </p>
                <p className="text-center text-md-start ">
                  <a href="https://api.whatsapp.com/send?phone=552138280370" target='_blank'>
                    <span>
                    <strong className='wht-text'>Whatsapp</strong>
                      <img src={logoWhatsapp} alt="icon-whatsapp" className='hide-icon' style={{ marginRight: "4px" }} />
                      +55 (21) 3828-0370
                    </span>
                  </a>
                </p>
              </div>
            </Col>

            <Col sm={12} md={3}>
              {/* Links úteis */}
              <div >
                <h6 className="pb-2" style={{ fontSize: '20px', fontWeight: 600, textTransform: 'uppercase' }}>{t('newfooter.usableLinks')}</h6>
                <ul className="">
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/termos-e-condicoes/">{t('newfooter.terms')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/aviso-de-privacidade/">{t('newfooter.privacy')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/cookies/">{t('newfooter.cookies')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://portaldotitular.c2rio.travel/titular_c2rio">{t('newfooter.lgpd')}</a></li>
                </ul>
              </div>
            </Col>

            <Col sm={12} md={3}>
              {/* Menu */}
              <div>
                <h6 className="pb-2" style={{ fontSize: '20px', fontWeight: 600, textTransform: 'uppercase' }}>{t('newfooter.menu')}</h6>
                <ul className="list-unstyled">
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/quem-somos/">{t('newfooter.who')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://support.c2rio.travel/hc/pt-br">{t('newfooter.questions')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://api.whatsapp.com/send?phone=552138280370">{t('newfooter.treatment')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://support.c2rio.travel/hc/pt-br/requests/new">{t('newfooter.talk')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://sig.c2rio.travel/">{t('newfooter.agentsLogin')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://agents.c2rio.travel/home">{t('newfooter.register')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/">{t('newfooter.website')}</a></li>
                  {/* <li className="styled-link"><a target="_blank" href="https://comprar.c2rio.travel/">{t('newfooter.buy')}</a></li> */}
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/blog/">{t('newfooter.blog')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.products')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.tickets')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.tours')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.private')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.transfer')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.booking')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/produtos/">{t('newfooter.guides')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.services')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.experiences')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.mice')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.fit')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.tourism')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.corpAct')}</a></li>
                  <li className="styled-link"><a target="_blank" href="https://c2rio.travel/servicos/">{t('newfooter.agencies')}</a></li>

                </ul>
              </div>
            </Col>

            <Col sm={12} md={3} lg={3}>
              {/* Redes sociais e cadastro */}
              <div >
                <h6 className="pb-2 " style={{ fontSize: '20px', fontWeight: 600, textTransform: 'uppercase' }}>{t('newfooter.socialMedia')}</h6>
                <nav aria-label="Redes Sociais">
                  <ul className="list-unstyled d-flex justify-content-between">
                    <li><a target="_blank" href="https://www.tripadvisor.com.br/Attraction_Review-g303506-d9555526-Reviews-C2Rio_Tours_Travel-Rio_de_Janeiro_State_of_Rio_de_Janeiro.html">
                    <img src={TripAdvisor} alt="selo tripadvisor" loading='lazy'
                            style={{ color: "#FFF", marginRight: "10px", height: "25px" }} />
                    </a></li>

                    <li><a target="_blank" href="https://www.instagram.com/c2rio.travel/">
                    <FontAwesomeIcon
                    icon={["fab", "instagram"]}
                    size="lg"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                    </a></li>

                    <li><a target="_blank" href="https://www.facebook.com/c2rio.travel">
                    <FontAwesomeIcon
                    icon={["fab", "facebook-f"]}
                    size="lg"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                    </a></li>

                    <li><a target="_blank" href="https://www.youtube.com/c/C2RioToursTravel">
                    <FontAwesomeIcon
                    icon={["fab", "youtube"]}
                    size="lg"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                    </a></li>
                    <li><a target="_blank" href="https://www.linkedin.com/company/c2rio/">
                    <FontAwesomeIcon
                    icon={["fab", "linkedin-in"]}
                    size="lg"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                    </a></li>
                  </ul>
                </nav>              

                <div className="mb-5 mb-md-0 py-3"> 
                    <RdStationForm/>
                  </div>
                <div className="mb-5 mb-md-0 py-3"> 
                <h6 className="pb-2 " style={{ fontSize: '20px', fontWeight: 600, textTransform: 'uppercase' }}>{t('newfooter.safe')}</h6>
                <div className="icons-footer payment d-flex gap-2 flex-wrap">
                  <FontAwesomeIcon
                    icon={["fab", "cc-visa"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-mastercard"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-diners-club"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <FontAwesomeIcon
                    icon={["fab", "cc-amex"]}
                    size="2x"
                    style={{ color: "#FFF", width: "36px" }}
                  />
                  <img src={Elo} alt="ícone Elo Icon" loading='lazy' />
                  <img src={Hiper} alt="ícone Hiper Icon" loading='lazy' />
                </div>
                  </div>
              </div>
            </Col>
          </Row>
        </div>        
        

      </div>
      <div className="content-mid">
        <div className="container">
          <Row className='gap-4 gap-md-2 gap-lg-0 '>
            <Col sm={12} md={12} lg={4} className='d-flex align-items-center justify-content-center justify-content-md-start gap-2'>
              <small className="footer-small">{t('newfooter.aCompany')}</small>
              <a href="https://iter.com.br/" target='_blank'><img src={logoGrupoIter} alt="Logomarca Grupo Iter" /></a>
            </Col>
            <Col sm={12} md={12} lg={8}>
              <div className="d-flex align-items-center justify-content-center gap-4 flex-column flex-md-row ">
                <small className="footer-small " >{t('newfooter.sealsCertifications')}</small>
                <div className="d-flex  gap-3 align-items-center flex-nowrap">
                  <img src={logoAbav} width={70} alt='logo ABAV' />
                  <a href="https://cadastur.turismo.gov.br/hotsite/#!/public/pesquisar-prestador/inicio?filtro=%7B%22currentPage%22:1,%22pageSize%22:10,%22sortFields%22:%22nomePrestador%22,%22sortDirections%22:%22ASC%22,%22filtros%22:%7B%22noPrestador%22:%22C2Rio%22,%22localidade%22:%22%22,%22nuAtividadeTuristica%22:%22Ag%C3%AAncia%20de%20Turismo%22,%22souPrestador%22:false,%22souTurista%22:true,%22localidadeNuUf%22:%2219%22,%22flPossuiVeiculo%22:%22%22%7D%7D#pesquisar" target="_blank"><img src={logoCadastur} width={170} alt='logo CADASTUR' /></a>
                  <img src={logoSkal} width={90} alt='logo Skal' />
                  <img src={logoVisitRio} width={90} height={30} alt='logo Visit Rio' />
                </div>
                <div>
                  <a href="https://transparencyreport.google.com/safe-browsing/search?url=c2rio.travel&amp;hl=pt-PT" target="_blank"><img src={logoGSafe} width={130} alt='logo Google Sage Browsing' /></a>
                </div>

              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className=" w-100">
        <div className="container">
          <div className='tags-container'>
            {tags.map((tag:string)=> {
              console.log(tag, tag.replaceAll('ç', 'c').replaceAll(" ", "-").toLocaleLowerCase());
              return (
                <div className="tag">
              <a href={'https://c2rio.travel/tag/' + tag.replaceAll('ç', 'c').replaceAll(" ", "-").toLocaleLowerCase()} target="_blank">{tag}</a>
            </div>
              )
            })}
            
          </div>
          <div className='d-flex justify-content-center align-items-center text-small'>
            <p>Copyrighht © 2024 C2Rio Tours & Travel</p>
          </div>
        </div>
      </div>

    </footer>    
  );
};

export default Footer;
